import { defineStore } from 'pinia'
import { ref } from 'vue'
import { dictionary } from '@/api'

export const useDictionary = defineStore('dictionary', () => {
  const adStatus = ref([])
  const adOperationType = ref([])
  const adAccountOpenType = ref([])
  const walletChangeType = ref([])

  // 广告账户状态
  const getAdStatus = async () => {
    try {
      const res = await dictionary('recharge_advertiser_account_status')
      adStatus.value = res.data
    } catch (error) {
      console.error(error)
    }
  }
  // 广告账户操作类型
  const getAdOperationType = async () => {
    try {
      const res = await dictionary('recharge_operation_type')
      adOperationType.value = res.data
    } catch (error) {
      console.error(error)
    }
  }
  // 广告账户类型
  const  getAdAccountOpenType = async () => {
    try {
      const res = await dictionary('recharge_advertiser_account_open_type')
      adAccountOpenType.value = res.data
    } catch (error) {
      console.error(error)
    }
  }
  // 钱包消耗类型字典
  const getWalletChangeType = async () => {
    try {
      const res = await dictionary('recharge_wallet_change')
      walletChangeType.value = res.data
    } catch(error) {
      console.error(error)
    }
  }

  return {
    adStatus,
    adOperationType,
    adAccountOpenType,
    walletChangeType,
    getAdStatus,
    getAdOperationType,
    getAdAccountOpenType,
    getWalletChangeType
  }
})