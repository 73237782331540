import { createRouter, createWebHashHistory } from 'vue-router'
import beforeEach from '@/router/filters/beforeEach'
import Layout from '@/components/layout/index.vue'

export const routes = [
  {
    path: '/login',
    meta: {
      isHidden: true,
    },
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/register',
    meta: {
      isHidden: true,
    },
    component: () => import('@/views/register/index.vue'),
  },
  {
    path: '/redirect/:path(.*)',
    name: 'Redirect',
    meta: {
      isHidden: true,
    },
    component: () => import('@/views/redirect/index.vue')
  },
  {
    path: '/',
    component: Layout,
    name: 'Layout'
  }
]

export const asyncRoutes = [
  {
    path: '/home',
    name: 'Home',
    meta: {
      title: '首页',
      icon: 'dashboard',
      id: 10001
    },
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/openAccount',
    name: 'OpenAccount',
    meta: {
      title: '开户管理',
      icon: 'openAd',
      id: 10002
    },
    children: [
      {
        path: '/openAccount/buy',
        name: 'Open',
        meta: {
          title: '账户购买',
          icon: ''
        },
        component: () => import('@/views/openAccount/buy/index.vue')
      },
      {
        path: '/openAccount/openRecord',
        name: 'OpenRecord',
        meta: {
          title: '开户记录',
          icon: ''
        },
        component: () => import('@/views/openAccount/openRecord/index.vue')
      }
    ]
  },
  {
    path: '/adAccount',
    name: 'AdAccount',
    meta: {
      title: '广告账户',
      icon: 'account',
      id: 10003
    },
    children: [
      {
        path: '/adAccount/accountApply',
        name: 'AccountApply',
        meta: {
          title: '账户管理',
          icon: ''
        },
        component: () => import('@/views/adAccount/accountApply/index.vue')
      },
      {
        path: '/adAccount/recharge',
        name: 'AccountRecharge',
        meta: {
          title: '账户充值',
          icon: '',
          isHidden: true
        },
        component: () => import('@/views/adAccount/accountApply/components/recharge.vue')
      },
      {
        path: '/adAccount/accountManagement',
        name: 'AccountManagement',
        meta: {
          title: '账户申请记录',
          icon: ''
        },
        component: () => import('@/views/adAccount/accountManagement/index.vue')
      }
    ]
  },
  {
    path: '/financeManagement',
    name: 'FinanceManagement',
    meta: {
      title: '财务管理',
      icon: 'wallet',
      id: 10004
    },
    children: [
      {
        path: '/financeManagement/recharge',
        name: 'Recharge',
        meta: {
          title: '钱包充值',
          icon: ''
        },
        component: () => import('@/views/financeManagement/recharge/index.vue')
      },
      {
        path: '/financeManagement/rechargeRecord',
        name: 'RechargeRecord',
        meta: {
          title: '充值记录',
          icon: ''
        },
        component: () => import('@/views/financeManagement/rechargeRecord/index.vue')
      },
      {
        path: '/financeManagement/walletRecord',
        name: 'WalletRecord',
        meta: {
          title: '钱包记录',
          icon: ''
        },
        component: () => import('@/views/financeManagement/walletRecord/index.vue')
      }
    ]
  },
  {
    path: '/opUserMg',
    name: 'OpUserMg',
    meta: {
      title: '运营管理',
      icon: 'operation',
      id: 5
    },
    component: () => import('@/views/opUserMg/index.vue')
  },
  {
    path: '/userMg',
    name: 'UserMg',
    meta: {
      title: '用户管理',
      icon: 'user',
      id: 1
    },
    component: () => import('@/views/userMg/index.vue')
  },
  {
    path: '/opAdAccountMg',
    name: 'OpAdAccountMg',
    meta: {
      title: '账户管理',
      icon: 'ad',
      id: 2
    },
    children: [
      {
        path: '/opAdAccountMg/ad',
        name: 'Ad',
        meta: {
          title: '库存管理',
          icon: ''
        },
        component: () => import('@/views/opAdAccountMg/ad/index.vue')
      },
      {
        path: '/opAdAccountMg/openAccount',
        name: 'OpOpenAccount',
        meta: {
          title: '开户管理',
          icon: ''
        },
        component: () => import('@/views/opAdAccountMg/openAccount')
      },
      {
        path: '/opAdAccountMg/accountOperation',
        name: 'AccountOperation',
        meta: {
          title: '账户操作管理',
          icon: ''
        },
        component: () => import('@/views/opAdAccountMg/accountOperation')
      }
    ]
  },
  {
    path: '/report',
    name: 'Report',
    meta: {
      title: '数据报表',
      icon: 'report',
      id: 10005
    },
    children: [
      {
        path: '/report/analyze',
        name: 'Analyze',
        meta: {
          title: '数据分析',
          icon: ''
        },
        component: () => import('@/views/report/index.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior: () => ({ top: 0 }),
  routes
})
router.beforeEach(beforeEach)

export default router