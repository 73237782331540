<template>
  <div class="flex h-dvh overflow-x-hidden">
    <div class="app-menu-box h-full overflow-y-auto">
      <menu-com />
    </div>
    <div class="app-container flex-1 flex flex-col">
      <header>
        <header-com />
      </header>
      <div class="app-main-container p-4 flex-1">
        <router-view v-slot="{ Component }">
          <keep-alive :include="cachedTags">
            <component :is="Component" :key="route.fullPath" />
          </keep-alive>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderCom from '@/components/header/index.vue'
import MenuCom from '@/components/menu/index.vue'
import { useTagsStore } from '@/store/tags'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

export default {
  name: 'Layout',
  components: {
    HeaderCom,
    MenuCom
  },
  computed: {
    cachedTags() {
      const store = useTagsStore()
      return store.cachedTags ? store.cachedTags : []
    }
  },
  setup() {
    const route = useRoute()
    const currentRoute = computed(() => route.path)
    const routeName = computed(() => route.name)
    console.log(route)
    return {
      currentRoute,
      routeName,
      route
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  border-radius: 0 0 0 20px;
  position: relative;
  //max-width: calc(100% - var(--itsop-menu-width));
  width: 0;
}
header {
  position: absolute;
  top: 0;
  //width: calc(100% - var(--itsop-menu-width));
  width: 100%;
  background: #fff;
  height: var(--itsop-header-sum-height);
  z-index: 100;
  box-sizing: border-box;
  border-radius: 20px 0 0;
}
.app-menu-box {
  background-color: var(--itsop-menu-bg);
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  min-width: fit-content;
  flex-shrink: 1;
  padding-top: 16px;
}
.app-main-container {
  margin-top: var(--itsop-header-sum-height);
  overflow-y: auto;
  background-color: var(--itsop-background);
}
</style>
