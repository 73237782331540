<template>
  <div class="contentBox px-4 w-full">
    <div class="content flex items-center">
      <div class="flex-1 flex">
        <svg-icon class="cursor-pointer" v-show="!menuCollapse" name="collapse" color="#515f8f" @click="handleSetMenuCollapse"></svg-icon>
        <svg-icon class="cursor-pointer" v-show="menuCollapse" name="expand" color="#515f8f" @click="handleSetMenuCollapse"></svg-icon>
      </div>
      <div class="border-box flex justify-center items-center cursor-pointer" @click="handleChangeLocale">
        <svg-icon name="language"></svg-icon>
      </div>
      <el-dropdown class="ml-4" @command="handleCommand">
        <div class="flex items-center">
          <el-avatar class="mr-2" :size="30" :src="circleUrl" />
          {{ userData ? userData.userName || userData.name : '' }}
        </div>
<!--        <template #dropdown>-->
<!--          <el-dropdown-menu>-->
<!--            <el-dropdown-item command="changePass">修改密码</el-dropdown-item>-->
<!--          </el-dropdown-menu>-->
<!--        </template>-->
      </el-dropdown>
      <div class="border-box flex justify-center items-center cursor-pointer ml-4" @click="handleLogout">
        <el-icon><SwitchButton /></el-icon>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import { logout } from '@/api'
import { computed, ref } from 'vue'
import avatar from '@/assets/avatar1.png'
import { useGlobalStore } from '@/store'
import SvgIcon from '@/components/svgIcon/index.vue'
import { useI18n } from 'vue-i18n'
import { useDictionary } from '@/store/dict'

const router = useRouter()
const circleUrl = ref(avatar)
const store = useGlobalStore()
const userData = computed(() => store.userData)
const menuCollapse = computed(() => store.menuCollapse)

const handleLogout = () => {
  ElMessageBox.confirm('确定退出吗？', '提示', {
    type: 'warning'
  }).then(() => {
    logout({
      token: localStorage.getItem('token')
    }).then(() => {
      router.push('/login')
      localStorage.removeItem('token')
    })
  }).catch(() => {})
}

const handleCommand = (command) => {
  console.log(command)
  if (command === 'logout') {
    handleLogout()
  } else if (command === 'changePass') {
    console.log('改密码')
  }
}

// 菜单展开收起
const handleSetMenuCollapse = () => {
  store.setMenuCollapse()
}

// 改变语言
const { locale } = useI18n()
const handleChangeLocale = () => {
  // 切换 Vue I18n 的语言
  locale.value = locale.value === 'en' ? 'zh' : 'en'
  localStorage.setItem('lang', locale.value)
  store.setLocale(locale.value)

  // 切换语言字典都需要重新获取
  const dicStore = useDictionary()
  dicStore.getAdStatus()
  dicStore.getAdOperationType()
  dicStore.getAdAccountOpenType()
  dicStore.getWalletChangeType()
}
</script>

<style lang="scss" scoped>
.contentBox {
  position: absolute;
  top: 0;
}
.content {
  width: var(--itsop-content-width);
  margin: 0 auto;
  height: var(--itsop-header-height);
  line-height: var(--itsop-header-height);
  .logo {
    width: 200px;
  }
  a {
    color: var(--itsop-font-2);
  }
  .router-link-active {
    font-weight: bolder;
    color: var(--el-color-primary);
  }
  .border-box {
    color: #728095;
    border: 1px solid #f2f3f5;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    &:hover {
      background-color: var(--el-color-primary);
      color: #fff;
    }
  }
}
.el-dropdown {
  color: var(--itsop-font-0);
}
</style>