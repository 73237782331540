<template>
  <el-menu
    :default-active="currentRoute"
    @select="handleSelect"
    background-color="#0f1d48"
    active-text-color="#fff"
    :collapse="menuCollapse"
  >
    <template v-for="item in menu" :key="item.path">
      <el-sub-menu v-if="item.children && !item.meta.isHidden" :index="item.path">
        <template #title>
          <svg-icon :name="item.meta.icon"></svg-icon>
          <span>{{ t(item.meta.title) }}</span>
        </template>
        <template v-for="childItem in item.children" :key="childItem.path">
          <el-menu-item v-show="!childItem.meta.isHidden" :index="childItem.path" :route="childItem.path">
            <span>{{ t(childItem.meta.title) }}</span>
          </el-menu-item>
        </template>
      </el-sub-menu>
      <el-menu-item v-else v-show="!item.meta.isHidden" :index="item.path" :route="item.path">
        <svg-icon :name="item.meta.icon" v-show="item.meta.icon"></svg-icon>
        <span>{{ t(item.meta.title) }}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import { computed } from 'vue'
import router from '@/router'
import { useRoute } from 'vue-router'
import SvgIcon from '@/components/svgIcon/index.vue'
import { useGlobalStore } from '@/store'

const globalStore = useGlobalStore()
const cRouter = useRoute()
const menu = computed(() => globalStore.asyncRouters)
const currentRoute = computed(() => cRouter.path)
const menuCollapse = computed(() => globalStore.menuCollapse)

const handleSelect = (key) => {
  router.push({ path: key })
}
</script>

<style lang="scss">
.el-menu {
  border-right: none !important;
  .el-sub-menu__icon-arrow {
    display: none;
  }
  .svg-icon {
    font-size: 20px;
    margin-right: 16px;
    min-width: 20px;
  }
  &.el-menu--collapse {
    .svg-icon {
      margin-right: 0;
    }
  }
}
</style>