import request from '@/utils/request'

// 首页
export function adOverview() {
  return request({
    url: '/app/recharge/advertiser/account/accountOverview',
    method: 'get'
  })
}

// 用户信息
export function getUserData(data) {
  return request({
    url: process.env.VUE_APP_TARGET === 'manage' ? '/system/user' : '/app/user',
    method: 'get',
    data
  })
}

export function login(data) {
  return request({
    url: process.env.VUE_APP_TARGET === 'user' ? '/app/auth/login' : '/auth/login',
    method: 'POST',
    data
  })
}

export function logout(data) {
  return request({
    url: '/auth/logout',
    method: 'POST',
    data
  })
}

// 用户注册
export function userRegister(data) {
  return request({
    url: '/app/auth/register',
    method: 'POST',
    data
  })
}

// 字段
export function dictionary(dictType) {
  return request({
    url: `/system/dict/data/type/${dictType}`,
  })
}

// 邮箱验证码
export function getEmailCode(params) {
  return request({
    url: '/resource/email/code',
    params
  })
}

// 全局配置
export function getGlobalSetting(params) {
  return request({
    url: '/system/config/list'
  })
}

// 当前用户广告ID列表
export function adIdList() {
  return request({
    url: '/recharge/advertiser/account/list'
  })
}

// 获取路由
export function getAllRouters() {
  return request({
    url: '/system/menu/getRouters'
  })
}