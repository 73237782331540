<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>

<script>
import { useGlobalStore } from '@/store'
import { useDictionary } from '@/store/dict'
import { getAllRouters } from '@/api'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/es/locale/lang/en'
import { computed } from 'vue'

export default {
  name: 'App',
  setup() {
    const store = useGlobalStore()
    const locale = computed(() => (store.locale === 'zh' ? zhCn : en))

    store.setUserData()
    store.setGlobalSettings()

    const dicStore = useDictionary()
    dicStore.getAdStatus()
    dicStore.getAdOperationType()
    dicStore.getAdAccountOpenType()
    dicStore.getWalletChangeType()

    getAllRouters()

    return {
      locale,
    }
  },
}
</script>
<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
