import { defineStore } from 'pinia'
import { ref } from 'vue'
import { getUserData, getGlobalSetting } from '@/api'

export const useGlobalStore = defineStore('global', () => {
  const userData = ref(null)
  const globalSettings = ref([])
  const menuCollapse = ref(false)
  const locale = ref(localStorage.getItem('lang') || 'zh')
  const asyncRouters = ref([])

  // 用户用户信息
  const setUserData = async () => {
    try {
      const res = await getUserData()
      userData.value = res.data
    } catch (error) {
      console.log(error)
    }
  }

  // 获取全局配置
  const setGlobalSettings = async () => {
    try {
      const res = await getGlobalSetting()
      globalSettings.value = res.data
    } catch (error) {
      console.log(error)
    }
  }

  // 菜单展开收起
  const setMenuCollapse = () => {
    menuCollapse.value = !menuCollapse.value
  }

  const setLocale = (lc) => {
    locale.value = lc
  }

  const setRouters = (routers) => {
    asyncRouters.value = routers
  }

  return {
    userData,
    globalSettings,
    menuCollapse,
    locale,
    asyncRouters,
    setUserData,
    setGlobalSettings,
    setMenuCollapse,
    setLocale,
    setRouters
  }
})