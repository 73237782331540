import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import zhCN from 'element-plus/es/locale/lang/zh-cn.mjs'
import en from 'element-plus/es/locale/lang/en'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import router from '@/router'
import plugins from './plugins'
import { createPinia } from 'pinia'
import i18n from './i18n/index'
import '@/assets/icons/index'

import '@/styles/index.scss'
import 'element-plus/dist/index.css'

const app = createApp(App)
const pinia = createPinia()

for (let [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app
  .use(router)
  .use(pinia)
  .use(plugins)
  .use(i18n)
  .use(ElementPlus, {
    locale: localStorage.getItem('lang') ? localStorage.getItem('lang') === 'zh' ? zhCN : en : zhCN,
    size: 'default',
  })
  .mount('#app')

// if (localStorage.getItem('lang')) {
  // handleChangeLocale()
// }
