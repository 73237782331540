import axios from 'axios'
import { ElMessage } from 'element-plus'
import router from '@/router'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})
service.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  const lang = localStorage.getItem('lang')
  config.headers.Authorization = `Bearer ${token}`
  config.headers['content-language'] = lang && lang === 'en' ? 'en_US' : 'zh_CN'
  return config
})

service.interceptors.response.use(response => {
  if (response.data.code && response.data.code !== 200) {
    if (response.data.code === 401) {
      router.replace('/login')
    } else {
      ElMessage.error(response.data.msg)
    }

    return Promise.reject(response.data)
  }
  if (response.headers['download-filename']) {
    response.fileName = response.headers['download-filename']
    return response
  }
  return response.data
}, error => {
  if (error.message) {
    ElMessage.error(error.message)
  }
  return Promise.reject(error.response.data)
})

export default service