import { useGlobalStore } from '@/store'
import router from '@/router'
import { asyncRoutes } from '@/router'
import { getAllRouters } from '@/api'

export default function (to, from, next) {
  const globalStore = useGlobalStore()
  if (!globalStore.asyncRouters.length && to.path !== '/login' && to.path !== '/register') {
    // 请求获取菜单
    getAllRouters().then(res => {
      const ids = res.data.map(asyncRouter => asyncRouter.id)
      let routers = asyncRoutes.filter(f => {
        return ids.includes(f.meta.id)
      })
      globalStore.setRouters(routers)
      routers.forEach(v => {
        router.addRoute('Layout', v)
      })

      // 路由跳转
      if (to.path === '/') {
        if (process.env.VUE_APP_TARGET === 'manage') {
          next({
            path: '/opUserMg',
            replace: true
          })
        } else {
          next({
            path: '/home',
            replace: true
          })
        }
      } else {
        next({ ...to, replace: true })
      }
    })
  } else {
    next()
  }
}